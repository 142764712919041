/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css?family=Roboto+Source+Sans+Pro:300,400,600,700|Roboto:400,300,700,500,100');

/* Custom drop down Search input */

.dropdown {

  ion-searchbar {
    border: 1px solid #ECECED;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 7px auto 0px auto;
    height: 40px !important;
    width: 96% !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #60636C;
  }

  .searchbar-input {
    background: none !important;
    padding-inline-start: 28px !important;
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    left: 0px;
  }

  .searchbar-input-container {
    --box-shadow: none !important;
  }

  .sc-ion-searchbar-md-h {
    box-shadow: none !important;
  }

}

/* Custom drop down Search Route Map input */
.searchbar {
  position: absolute;
  z-index: 2;
  width: 100%;
  ion-searchbar {
    --border-radius: none;
    border: 1px solid #ECECED;
    --background: #FFFFFF;
    --color: #FFFFFF;
    --icon-color: #60636C;
    --placeholder-color: #60636C;
    --placeholder-font-style: 'normal';
    --placeholder-font-weight: 'normal';
    --placeholder-font-family: 'Roboto';
    margin: 10px auto 0px auto;
    height: 40px !important;
    width: 96% !important;
    ion-icon {
      margin-left: 0 !important;
      color: #B6B8BF;
    }
    .searchbar-input {
      padding-left: 44px !important;
    }
    .searchbar-input.sc-ion-searchbar-ios {
      padding-left: 30px !important;
    }
    .searchbar-input-container, .sc-ion-searchbar-md-h, .sc-ion-searchbar-ios{
      background: #FFFFFF !important;
    }
  }
}

/* Custom drop down Search Route List input */
.routeSearch {
  .searchbar-input {
    background: none !important;
    padding-inline-start: 28px !important;
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    left: 0px;
  }

  .searchbar-input-container
  {
    --box-shadow: none !important;
  }

  .sc-ion-searchbar-md-h {
    box-shadow: none !important;
  }

  ion-searchbar {
    /* Color/Grey/3 */
    border: 1px solid #ECECED;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 7px auto 0px auto;
    height: 40px !important;
    width: 96% !important;
  }
  
}



.show-sm {
    display: none;
}

@media (max-width: 800px) {
    .hide-sm {
        display: none !important;
    }

    .show-sm {
        display: block !important;
    }

    .show-md {
        display: none !important;
    }
}

@media (min-width: 801px) {
    .hide-md {
        display: none !important;
    }
    
}